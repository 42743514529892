@import url('../../helper/color.css');

@font-face {
    font-family: poppines_semibold;
    src: url('../../asset/Poppins/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: poppines_bold;
    src: url('../../asset/Poppins/Poppins-Bold.ttf');
}

@font-face {
    font-family: poppines_regular;
    src: url('../../asset/Poppins/Poppins-Regular.ttf');
}

.our_team_bg {
    background-image: url('../../asset/allbg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    padding: 80px 5px;
}

.our_team_head h5 {
    font-family: poppines_semibold;
    font-size: 28px;
    margin-bottom: 0;

}

.our_team_head p {
    font-family: poppines_regular;
    font-size: 15px
}

.our_team_contentbg {
    background-image: url('../../asset/atbg.png');
    background-repeat: no-repeat;
    background-size: cover;

}

.our_team_content {
    margin-top: 90px !important;
}

.our_team_content h5 {
    font-family: poppines_bold;
    font-size: 21px;
    margin-top: 15px;
    color: var(--third);

}

.our_team_content p {
    font-family: poppines_regular;
    font-size: 16px;
    color: #999999;

}