@import url('../../helper/color.css');


@font-face {
    font-family: poppines_bold;
    src: url('../../asset/Poppins/Poppins-Bold.ttf');
}

@font-face {
    font-family:poppines_semibold;
    src: url('../../asset/Poppins/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: poppines_regular;
    src: url('../../asset/Poppins/Poppins-Regular.ttf');
}

.blog_page_bg {
    background-image: url('../../asset/allbg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 80px 5px;
}

.blog_page_content_head h3 {
    font-family: poppines_semibold;
    font-size: 30px;
    color: var(--third);
}

.blog_page_content h3 {
    font-family: poppines_semibold;
    font-size: 30px;
    color: var(--third);
}

.blog_page_content {
    padding-top: 90px;
    background-image: url('../../asset/atbg.png');
    background-size: cover;
    background-repeat: no-repeat;
}


.cd_title p{
    font-family:poppines_semibold;
    font-size: 15px;
    color: #929292;

}

.cd_body p{
    font-family: poppines_regular;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3;
    -webkit-box-orient: vertical;

}

.cd_title p img{
    width: 10%;
    border-radius: 100px;
    padding: 5px;
}

.btn:hover {
    background-color: var(--secondary) !important;
}

.tags {
    border: 1px solid #e2e2e2;
    padding: 15px;
    border-radius: 10px;

}

.tags h6 {
    background-color: var(--homemade);
    color: var(--primary1);
    border-radius: 10px;
    padding: 10px;
    font-family: poppines_semibold;
    word-wrap: break-word;
    font-size: 13px;


}

.blog_side_head {
    font-family: poppines_bold;
    font-size: 25px;
    color: var(--third);

}

.accordion-body ul {
    font-family: poppines_regular;
    line-height: 30px;
    color: var(--third);

}

.accordion-button {
    font-family: poppines_semibold;
    color: var(--third) !important;

}

.accordion-button:not(.collapsed) {
    font-family: poppines_semibold;
    color: var(--planticon) !important;
    background-color: var(--plant) !important;
}


.blog_side_category {
    border: 1px solid rgb(223, 223, 223);
    padding: 15px;
    border-radius: 10px;
    margin-top: 20px;
}

.blog_side_category h6 {
    font-family: poppines_semibold;
    font-size: 20px;
    color: var(--third);
    /* margin-top: 20px; */
    padding-bottom: 10px;

}

.blog_side_category h6 img {
    /* background-color: var(--homemade); */
    width: 12%;
    border-radius: 100px;
    padding: 5px;
}