@import url('../../helper/color.css');

@font-face {
    font-family: poppines_semibold;
    src: url('../../asset/Poppins/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: poppines_regular;
    src: url('../../asset/Poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: brfont;
    src: url('../../asset/brfont/BERNHARD\ SCHOENSCHRIFT\ EF\ \ REGULAR\ \(2\).TTF');
}

.con_bg {
    background-image: url('../../asset/allbg.png');
    padding: 80px 5px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}


.con_bg h5 {
    font-family: poppines_semibold;
    font-size: 30px;
    color: var(--third);

}

.con_bg p {
    font-family: poppines_regular;
    font-size: 15px;

}

.PrivateTabIndicator-colorSecondary-3{
    background-color: var(--primary1) !important;
}


.con_content {
    background-image: url('../../asset/atbg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 90px;
    padding-bottom: 100px;
}

.con_icon img {
    width: 100%;
    background-color: var(--rest);
    border-radius: 100%;
    padding: 18px;

}

.con_icon2 img {
    width: 100%;
    background-color: var(--plant);
    border-radius: 100%;
    padding: 15px;
}


.con_icon p {
    font-family: poppines_semibold;
    font-weight: bolder;
    font-size: 22px;
    margin-top: 10px;
    color: var(--third);

}

.con_icon2 p {
    font-family: poppines_semibold;
    font-weight: bolder;
    font-size: 22px;
    margin-top: 10px;
    color: var(--third);
}