@import url('../../helper/color.css');

@font-face {
    font-family: poppines_bold;
    src: url('../../asset/Poppins/Poppins-Bold.ttf');
}

@font-face {
    font-family: poppines_regular;
    src: url('../../asset/Poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: poppines_semibold;
    src: url('../../asset/Poppins/Poppins-SemiBold.ttf');
}


.contactus_bg{
    background-image: url('../../asset/banner/cont.jpg') !important;
    background-position: center;
    background-size: cover;
   padding: 80px 5px;
}


.contactus_bg h6{
    font-family: poppines_semibold;
    font-size: 30px;
    color: var(--third);
}

.contactus_bg p{
    font-family: poppines_regular;
    font-size: 18px;
    color: var(--third);

}

.contactus_cont{
    background-image: url('../../asset/atbg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 90px;
}

.contactus_form button{
    width: 50%;
    background-color: var(--primary1);
    border: none;
    margin-top: 0 !important;
    font-family: poppines_regular;

}