@import url('../../../../helper/color.css');

@font-face {
    font-family: brfont;
    src: url('../../../../asset/brfont/BERNHARD\ SCHOENSCHRIFT\ EF\ \ REGULAR\ \(2\).TTF');
}

@font-face {
    font-family: poppines_semibold;
    src: url('../../../../asset/Poppins/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: poppines_regular;
    src: url('../../../../asset/Poppins/Poppins-Regular.ttf');
}

.abg{
    background-image: url('../../../../asset/abg.png');
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 90px;
}

.at_content h1{
    font-family: brfont;
    font-size: 60px;
    color: var(--primary1);
}

.at_content p{
    font-family: poppines_regular;
    font-size: 15px;
    line-height: 25px;
}

.at_content h1 span{
    color: var(--third);
    font-family: poppines_semibold;
    font-size: 35px;


}

.at_content button{
    border: none;
    background-color: var(--primary1);
    color: #fff;
    padding: 10px 15px 10px 15px;
    font-size: 18px;
    font-family:poppines_regular ;
    border-radius: 100px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.at_div{
    display: flex;
    justify-content: center;
    align-items: center;

}

.at_div img{
    width: auto;

}

.at_div p{
    font-family: poppines_regular;
    font-size: 15px;
    line-height: 25px;
    margin-left: 20px;
}


@media only screen and (max-width: 1024px){
    .at_div img{
        display: none;
    
    }

    .at_div p{
    margin-left: 0px;

    }
}