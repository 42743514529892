@import url('../../helper/color.css');

@font-face {
    font-family: poppines_semibold;
    src: url('../../asset/Poppins/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: poppines_regular;
    src: url('../../asset/Poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: brfont;
    src: url('../../asset/brfont/BERNHARD\ SCHOENSCHRIFT\ EF\ \ REGULAR\ \(2\).TTF');
}

.aboutus_bg{
    background-image: url('../../asset/banner/ab.jpg');
    background-size: cover;
    background-position: top;
    padding: 80px 5px;
}

.aboutus_bg p{
    font-family: poppines_regular;
    font-size: 16px;

}

.aboutus_bg h3{
    font-family: poppines_semibold;
    font-size: 32px;


}

.all_bg{
    background-image:url('../../asset/atbg.png');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;

}

.all_at_Content{
    margin-top: 100px !important;
}

.all_at_Content p{
    font-family: poppines_regular;
    font-size: 15px;
    line-height: 28px;

}


