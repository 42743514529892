@import "../helper/color.css";

@font-face {
    font-family: poppines_medium;
    src: url('../asset/Poppins/Poppins-Medium.ttf');
}

@font-face {
    font-family: poppines_bold;
    src: url('../asset/Poppins/Poppins-Bold.ttf');
}


.header_bg {
    background-color: white;
    border-bottom: 5px solid #F5ECDE;
    position: sticky;
    top: 0;
    z-index: 9;
}

.header_list {
    display: flex;
    justify-content: end;
    align-items: center;
}

.header_list h6 {
    padding-left: 30px;
    font-size: 15px;
    margin-bottom: 0;
    font-family: poppines_bold;
    text-transform: uppercase;

}

.header_bg button {
    margin-left: 15px;
    border: none;
    border-radius: 100px;
    padding: 5px 15px 5px 15px;
    background-image: linear-gradient(to right, #028149, #6EBE60);
    color: white;
}

.hover_service h6 {
    color: var(--third);
}

.hover_service:hover .services {
    transition: 0.3s;
    display: block;
    cursor: pointer;

}



.services {
    display: none;
    background-color: #fff;
    position: absolute;
    padding: 20px;
    font-family: poppines_medium;
    /* margin-top: 10px; */
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.services p span {
    color: var(--primary1);
}

.side_header_list h6 {
    font-family: poppines_medium;
    font-size: 16px;
    line-height: 35px;
    border-bottom: 1px solid rgb(216, 216, 216);
    color: var(--third);


}

.side_menu_button {
    border: none;
    background-color: var(--primary1);
    border-radius: 100px;
    padding: 5px 15px;
    font-family: poppines_medium;
    color: #fff;
    margin-top: 10px;

}

.side_menu_links {
    color: var(--third) !important;
}

.acc_bt button:not(.collapsed) {
    background-color: #ffffff !important;
    color: var(--third) !important;

}

.acc_bt button {
    background-color: #ffffff !important;
    padding-left: 0;
    box-shadow: none !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}



@media only screen and (max-width: 996px) and (min-width: 320px) {
    .header_list h6 {
        padding-left: 30px;
        font-size: 15px;
        margin-bottom: 0;
        font-family: poppines_regular;
        display: none;

    }

    .menu_button {
        display: none;
    }

    .menu_icon {
        font-size: 28px;
        display: block;
    }
}


@media only screen and (max-width: 1200px) and (min-width:995px) {
    .homee_logo img {
        width: 100%;
    }

    .menu_icon {
        font-size: 28px;
        display: none;
    }

    .header_list h6 {
        padding-left: 20px;
        font-size: 13px;
        margin-bottom: 0;
        font-family: poppines_bold;
        display: block;

    }

    .menu_button {
        display: none;
    }

}



@media only screen and (max-width: 1441px) and (min-width:1200px) {

    .header_list h6 {
        padding-left: 20px;
        font-size: 13px;
        margin-bottom: 0;
        font-family: poppines_bold;
        /* display: none; */

    }

    .menu_icon {
        font-size: 28px;
        display: none;
    }

}

@media only screen and (max-width: 2560px) and (min-width:1440px) {
    .menu_icon {
        font-size: 28px;
        display: none;
    }

}