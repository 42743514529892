@import url('../../../helper/color.css');


@font-face {
    font-family: poppines_bold;
    src: url('../../../asset/Poppins/Poppins-Bold.ttf');
}

@font-face {
    font-family: poppines_regular;
    src: url('../../../asset/Poppins/Poppins-Regular.ttf');
}



.con_names h5{
    font-family: poppines_bold;
    color: var(--third);
    text-align: left;
    font-size: 25px;
    margin-bottom: 0;

}

.con_names p{
    font-family: poppines_regular;
    color: var(--third);
    font-size: 16px;
    text-align: left;
    text-transform: none;
    margin-bottom: 0;
    color: rgb(158, 158, 158);

}

.con_panel_content h5{
    font-family: poppines_bold;
    font-size: 30px;
    margin-top: 25px;
    color: var(--third);

}

.con_panel_content p{
    font-family: poppines_regular;
    font-size: 18px;
    color: rgb(82, 82, 82);


}

/* .css-1e6y48t-MuiButtonBase-root-MuiButton-root:active{
    background-color: red !important;
} */