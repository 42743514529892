@import '../../../helper/color.css';
.slick-next{
    right: 8% !important;
    /* background-color:var(--primary1) ; */
    padding: 20px;
    
}

.slick-prev{
    left: 8% !important;
    z-index: 99;
}