@import url('../../../helper/color.css');

@font-face {
    font-family: poppines_regular;
    src: url('../../../asset/Poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: poppines_semibold;
    src: url('../../../asset/Poppins/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: brfont;
    src: url('../../../asset/brfont/BERNHARD\ SCHOENSCHRIFT\ EF\ \ REGULAR\ \(2\).TTF');
}

.av{
    /* display: inline-flex;
    justify-content: start;
    align-items: center; */
    border: 1px solid #DCDCDC;
    border-radius: 10px;
  
}



.av h5{
    font-family: poppines_semibold;
    font-size: 22px;
    font-weight: bolder;
    margin-bottom: 0;
    color: var(--primary1);
    

}

.av p{
    font-family: poppines_regular;
    font-size: 15px;
    color: var(--third);

}
