@import url('../helper/color.css');

@font-face {
    font-family: poppines_semibold;
    src: url('../asset/Poppins/Poppins-SemiBold.ttf');
}


@font-face {
    font-family: poppines_regular;
    src: url('../asset/Poppins/Poppins-Regular.ttf');
}


.footer_bg {
    background-color: var(--forth);
    margin-top: 90px;
}

.at_homee p {
    font-family: poppines_regular;
    color: #fff;
    font-size: 15px;
    text-align: justify;


}

.address h3{
    color: var(--primary2);
    font-family: poppines_semibold;
    font-size: 18px;
}

.address p{
    text-align: left;
}

.inquiries{
    background-color: var(--primary2);
    padding: 3px 10px;
    border-radius: 8px;

}

.inquiries p {
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
}

.inquiries center p {
    font-weight: bold;
    margin-top: 8px;
    
}

.inquiries p span {
    background-color: #fff;
    border-radius: 100%;
    padding: 3px 7px;
    display: inline-block;
    font-size: 13px;


    /* margin-left: 10px; */
    color: var(--forth);

}


.social_icons p {
    font-size: 20px;
}

.social_icons p span {
    background-color: var(--primary2);
    border-radius: 100%;
    padding: 3px 7px;
    display: inline-block;
    font-size: 13px;


    /* margin-left: 10px; */
    color: var(--forth);

}

.quick_links h6 {
    font-family: poppines_semibold;
    font-size: 20px;
    color: var(--primary2);

}

.quick_links ul {
    padding-left: 0;
}

.quick_links ul li {
    font-family: poppines_regular;
    font-size: 16px;
    list-style: none;
    text-decoration: none;
    color: #fff;
    line-height: 35px;

}

.quick_links ul li span {
    color: var(--primary2);
}



.qrcode_head h6 {
    color: var(--primary2);
    font-family: poppines_regular;
    font-size: 15px;
    font-weight: bold;

}



.qrcode_head p {
    color: #fff;
    font-family: poppines_regular;
    font-size: 15px;
}


.qrcode_img p{
    color: var(--primary2);
    font-family: poppines_regular;
    text-align: center;

}

.an_qr{
    width: 100%;
}

.ios_qr{
    width: 100%;
}

.qrcode_img button{
    border: 1px solid var(--primary2);
    margin-top: 15px;
    width: 100%;
    border-radius: 10%;
    background-color: transparent;
    color: #fff;
    font-size: 15px;
    padding: 5px 5px;
}

.all_form button {
    border: none;
    background-color: var(--primary2);
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    font-family: poppines_regular;
    font-weight: bold;
    color: #fff;

}

.flable {
    color: #fff;
    font-family: poppines_regular;

}

.flable span {
    color: rgb(255, 110, 110);
}


@media only screen and (max-width: 425px){
    .qrcode_head{
        text-align: center;
    };
    
  
    
    }