.gallery{
    margin-top: 90px;
    background-image: url('../../../../asset/gallery/gallerybg.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
}

.image_small {
    width: 100% ;
}