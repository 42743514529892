@import url('../../../helper/color.css');

@font-face {
    font-family: poppines_semibold;
    src: url('../../../asset/Poppins/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: poppines_bolditalic;
    src: url('../../../asset/Poppins/Poppins-BoldItalic.ttf');
}

@font-face {
    font-family: brfont;
    src: url('../../../asset/brfont/BERNHARD\ SCHOENSCHRIFT\ EF\ \ REGULAR\ \(2\).TTF');
}

@font-face {
    font-family: poppines_regular;
    src: url('../../../asset/Poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: poppines_bolditlic;
    src: url('../../../asset/Poppins/Poppins-BoldItalic.ttf');
}

.career_head_bg{
    background-image: url('../../../asset/allbg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 5px;
}

.career_head_bg h3{
    font-family: poppines_semibold;
    font-size: 30px;

}

.career_head_bg p {
    font-family: poppines_regular;
    font-size: 18px;

}

.career_head_content{
    margin-top: 90px;
}

.career_head_content h6{
    font-size: 25px;
    font-family: poppines_bolditlic;

}

.career_head_content p{
    font-family: poppines_regular;
    font-size: 15px;
    line-height: 28px;
    text-align: justify;

}

.career_head_content h5{
    font-family: poppines_semibold;
    /* margin-bottom: 50px; */
    color: var(--third);
    font-size: 25px;

}

.react-tabs__tab h4{
    font-size: 22px;
    font-family: poppines_semibold;
    padding: 8px 25px;
    color: var(--primary1);

}


.react-tabs__tab-panel h2{
    font-family: poppines_bolditlic;
    margin-top: 40px;
    

}


ul li {
    font-family: poppines_regular;
    font-size: 15px;
    line-height: 28px;
 
}

.career_form{
    background-color: rgb(240, 240, 240);
    padding: 40px;
}

.career_form button{
display: flex;
justify-content: center;
align-items: center;
margin-top: 0 !important;
width: 100%;
font-family: poppines_regular;
background-color: var(--primary1);
border: none;

}

.f_lable{
    font-family: poppines_regular;
    font-size: 15px;

}

.f_lable span{
    color: red;
}

.pagehead {
    font-size: 22px;
    font-family: poppines_semibold;
}
