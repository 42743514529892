@import url('../../../../helper/color.css');

@font-face {
    font-family: brfont;
    src: url('../../../../asset/brfont/BERNHARD\ SCHOENSCHRIFT\ EF\ \ REGULAR\ \(2\).TTF');
}

@font-face {
    font-family: poppines_bold;
    src: url('../../../../asset/Poppins/Poppins-Bold.ttf');
}

@font-face {
    font-family: poppines_semibold;
    src: url('../../../../asset/Poppins/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: poppines_medium;
    src: url('../../../../asset/Poppins/Poppins-Medium.ttf');
}

.homeeservice img{
    width: 50px;
}

.homeeservice h1 {
    font-family:brfont ;
    font-size: 58px;
    color: var(--primary1);
    margin-top: -20px;

   
}

.homeeservice span{
    color: var(--third);
    font-family: poppines_semibold;
    font-size: 28px;

    
}

.bg{
    background-image: url('../../../../asset/bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    
}