:root{
    --primary1 : #028149;
    --primary2 : #6EBE60;
    --secondary : #8A5D3B;
    --third : #4d4d4d;
    --forth:#1E1E1E;
    --homemade:#E8FFF5;
    --homemadeicon:#73AF95;
    --rest:#F5EEFF;
    --resticon:#9680B5;
    --plant:#FFF9EC;
    --planticon:#B8A57A;
    --pickup:#FFEBEB;
    --pickupicon:#B78C8C;
    --homeservice:#F8F8F8;
    --homeserviceicon:#ABABAB;

}