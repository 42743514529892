@import url('../../../../helper/color.css');

@font-face {
    font-family: poppines_semibold;
    src: url('../../../../asset/Poppins/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: poppines_regular;
    src: url('../../../../asset/Poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: brfont;
    src: url('../../../../asset/brfont/BERNHARD\ SCHOENSCHRIFT\ EF\ \ REGULAR\ \(2\).TTF');
}

.blog_bg {
    margin-top: 60px;
}

.slider_card {
    margin: 10px;
}

.card_title {
    font-family: poppines_semibold;
    font-size: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.card_description {
    font-family: poppines_regular;
    font-size: 15px;

}

.card button{
    border: none;
    background-color: #fff;
    color: var(--secondary);
    font-size: 18px;
    font-family: poppines_regular;


}

.card button span{
    color: var(--primary1);
}