@import url('../../../helper/color.css');


@font-face {
    font-family: poppines_semibold;
    src: url('../../../asset/Poppins/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: poppines_bold;
    src: url('../../../asset/Poppins/Poppins-Bold.ttf');
}

@font-face {
    font-family: poppines_regular;
    src: url('../../../asset/Poppins/Poppins-Regular.ttf');
}

.blogpage_bg {
    background-image: url('../../../asset/allbg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 80px 5px;
}

.blogpage_bg h5 {
    font-family: poppines_semibold;
    font-size: 30px;
    color: var(--third);

}

.blogpage_bg p span {
    font-family: poppines_regular;
    font-size: 15px;

}

.blogpage_content {
    background-image: url('../../../asset/atbg.png');

    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 90px;
}

.blog_side_cont h1 {
    font-family: poppines_bold;
    font-size: 30px;
    color: var(--primary1);
}

.blog_side_cont p{
    font-family: poppines_regular;
    font-size: 15px;
    line-height: 28px;
    color: var(--third);

}