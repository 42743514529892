@import url('../../helper/color.css');

@font-face {
    font-family: poppines_semibold;
    src: url('../../asset/Poppins/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: poppines_regular;
    src: url('../../asset/Poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: brfont;
    src: url('../../asset/brfont/BERNHARD\ SCHOENSCHRIFT\ EF\ \ REGULAR\ \(2\).TTF');
}


