@import url("../../../../../helper/color.css");

@font-face {
    font-family: poppines_bold;
    src: url('../../../../../asset/Poppins/Poppins-Bold.ttf');
}

@font-face {
    font-family: poppines_semibold;
    src: url('../../../../../asset/Poppins/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: poppines_regular;
    src: url('../../../../../asset/Poppins/Poppins-Regular.ttf');
}

/* <!=============================Homemade Start===================================!> */

.homemade {
    background-color: var(--homemade);
    display: inline-block;
    border-radius: 100%;
    padding: 30px;


}

.homemade img {
    margin-left: auto;
    margin-right: auto;
    width: 100%;

}

.homemade_head h5 {
    margin-top: 15px;
    font-size: 20px;
    font-family: poppines_bold;
    color: var(--third);

}

.homemade_head p {
    font-size: 15px;
    color: var(--secondary);
    font-family: poppines_regular;
}

.homemade_head p span {
    color: var(--primary1);
}


.homemade_bg {
    background-image: url('../../../../../asset/allbg.png');
    background-size: cover;
    background-position: center;
    padding: 80px 5px;

}

.homemade_bg h6 {
    font-family: poppines_semibold;
    font-size: 30px;
    color: var(--third);
}

.homemade_bg p {
    font-family: poppines_regular;
    font-size: 15px;

}

.Homemade_content {
    background-image: url('../../../../../asset/atbg.png');
    background-size: cover;

    padding-top: 90px;

}

.homemade_icon p{
    font-family: poppines_semibold;
    font-size: 30px;
    color: var(--primary1);

}

.homemade_icon p img {
    width: 7%;
    background-color: var(--homemade);
    border-radius: 100px;
    padding: 10px;
}



.homemade_cont p {
    font-family: poppines_regular;
    font-size: 15px;
    line-height: 25px;

}


/* <!=============================Homemade END===================================!> */

/* <!=============================restaurant Start===================================!> */

.rest_icon p img {
    width: 7%;
    background-color: var(--rest);
    border-radius: 100px;
    padding: 10px;
}

.rest_icon p{
    font-family: poppines_semibold;
    font-size: 30px;
    color: var(--resticon);

}

.restaurant {
    background-color: var(--rest);
    display: inline-block;
    border-radius: 100%;
    padding: 30px;
}

.restaurant img {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.restaurant_head h5 {
    margin-top: 15px;
    font-size: 20px;
    font-family: poppines_bold;
    color: var(--third);

}

.restaurant_head p {
    font-size: 15px;
    color: var(--secondary);
    font-family: poppines_regular;
}

.restaurant_head span {
    color: var(--primary1);

}


/* <!=============================restaurant end===================================!> */

/* <!=============================plant Start===================================!> */

.plant_icon p img {
    width: 7%;
    background-color: var(--plant);
    border-radius: 100px;
    padding: 10px;
}

.plant_icon p{
    font-family: poppines_semibold;
    font-size: 30px;
    color: var(--planticon);

}

.plant {
    background-color: var(--plant);
    display: inline-block;
    border-radius: 100%;
    padding: 30px;
}

.plant img {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.plant_head h5 {
    margin-top: 15px;
    font-size: 20px;
    font-family: poppines_bold;
    color: var(--third);

}

.plant_head p {
    font-size: 15px;
    color: var(--secondary);
    font-family: poppines_regular;
}

.plant_head span {
    color: var(--primary1);

}

/* <!=============================plant end===================================!> */

/* <!=============================Pickup Start===================================!> */

.pickup_icon p img {
    width: 7%;
    background-color: var(--pickup);
    border-radius: 100px;
    padding: 10px;
}

.pickup_icon p{
    font-family: poppines_semibold;
    font-size: 30px;
    color: var(--pickupicon);

}

.Pickup {
    background-color: var(--pickup);
    display: inline-block;
    border-radius: 100%;
    padding: 30px;
}

.Pickup img {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.Pickup_head h5 {
    margin-top: 15px;
    font-size: 20px;
    font-family: poppines_bold;
    color: var(--third);
}

.Pickup_head p {
    font-size: 15px;
    color: var(--secondary);
    font-family: poppines_regular;
}

.Pickup_head span {
    color: var(--primary1);

}

/* <!=============================Pickup end===================================!> */

/* <!=============================homeservice Start===================================!> */

.homeservice_icon p img {
    width: 7%;
    background-color: var(--homeservice);
    border-radius: 100px;
    padding: 10px;
}

.homeservice_icon p{
    font-family: poppines_semibold;
    font-size: 30px;
    color: var(--homeserviceicon);

}


.homeservice {
    background-color: var(--homeservice);
    display: inline-block;
    border-radius: 100%;
    padding: 30px;

}

.homeservice img {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.homeservice_head h5 {
    margin-top: 15px;
    font-size: 20px;
    font-family: poppines_bold;
    color: var(--third);
}

.homeservice_head p {
    font-size: 15px;
    color: var(--secondary);
    font-family: poppines_regular;
}

.homeservice_head span {
    color: var(--primary1);

}

/* <!=============================homeservice end===================================!> */