@import url('../../../../helper/color.css');

@font-face {
    font-family: poppines_semibold;
    src: url('../../../../asset/Poppins/Poppins-SemiBold.ttf');
}

@font-face {
    font-family: poppines_regular;
    src: url('../../../../asset/Poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: brfont;
    src: url('../../../../asset/brfont/BERNHARD\ SCHOENSCHRIFT\ EF\ \ REGULAR\ \(2\).TTF');
}

.at_userapp {
    margin-top: 90px;
    background-image: url('../../../../asset/userbg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

}

.at_userapp_head img {
    width: 50px;
}

.at_userapp_head h1 {
    font-family: brfont;
    font-size: 65px;
    color: var(--primary1);
    margin-top: -12px;
}

.at_userapp_head span {
    font-family: poppines_semibold;
    font-size: 30px;
    color: var(--third);


}


.user_store h6 {
    font-family: poppines_semibold;
    font-size: 18px;
    margin-bottom: 10px;
 


}

.at_userapp_content h3 {
    font-family: poppines_semibold;
    font-size: 25px;
    color: var(--third);

}

.at_userapp_content p {
    font-family: poppines_regular;
    font-size: 15px;
    line-height: 25px;

}

.at_userapp_content h3 span {
    background-color: var(--homemade);
    padding: 5px 10px;
    border-radius: 100%;
    font-size: 22px;
    color: var(--primary1);
}