@import url('../../../../helper/color.css');

@font-face {
    font-family: poppines_semibold;
    src: url("../../../../asset/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
    font-family: poppines_regular;
    src: url('../../../../asset/Poppins/Poppins-Regular.ttf');
}

.cookapp{
    margin-top: 90px;
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_row-title-text__1MuhU{
    font-family:poppines_semibold !important ;
    font-size: 20px !important;
    color: var(--third);
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-content__QOGZd .styles_row-content-text__2sgAB{
    font-family: poppines_regular !important;
    font-size: 15px !important;

}

P{
    font-family: poppines_regular !important;
    font-size: 15px !important;

}