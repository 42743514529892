@import url("../src/helper/color.css");
*{
    box-sizing: border-box;
  
    
    
}

a{
    text-decoration: none !important;
    color: var(--third) !important;
    
}

@media only screen and (max-width: 1024px) and (min-width:320px ){

    .pad{
        padding-right: 40px;
        padding-left: 40px;
    }
}

@media only screen and (max-width: 1441px) and (min-width:1024px){

    .pad{
        padding-right: 50px;
        padding-left: 50px;
    }
}

@media only screen and (max-width: 2560px) and (min-width:1440px){

    .pad{
        padding-right: 150px;
        padding-left: 150px;
    }
}


.headicon img{
    width: 50px;
}

.headicon h1 {
    font-family:brfont ;
    font-size: 58px;
    color: var(--primary1);
    margin-top: -20px;

   
}

.headicon span{
    color: var(--third);
    font-family: poppines_semibold;
    font-size: 28px;

    
}